import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";
import router from "@/router";

import { OrderModel } from "@/data/order/order.model.ts";
import { ProductModel } from "@/data/product/product.model.ts";

import { State, Getter, Action, Mutation } from "vuex-class";

@Component({
	name: "Page"
})
export default class PageComponent extends Vue {
	@Action("i18n/changeLanguage") private changeLanguage!: any;
	@Getter("i18n/getAvailableLangs") private availableLangs!: string[];
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = {
		orders: null,
		products: null
	};
	private contentIsReady: boolean = false;
	private currentPage: number = 1;

	private created() {
		this.getContent();
	}

	private computed() {
		this.getFilterObject();
	}

	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		router.push({ query: { per_page: "100" } });
		this.$route.query.page ? (this.currentPage = Number(this.$route.query.page)) : (this.currentPage = 1);
		if (this.i18nHasInitialized) {
			this.setLoading(true);
			this.content.orders = await new OrderModel().getList(this.$route.query);
			this.content.products = await new ProductModel().getList(false, this.$route.query);
			this.currentPage = Number(this.$route.query.page);
			this.contentIsReady = true;
			this.setLoading(false);
		}
	}

	@Watch("$route")
	private async getOrders() {
		this.$route.query.page ? (this.currentPage = Number(this.$route.query.page)) : (this.currentPage = 1);
		if (this.i18nHasInitialized) {
			this.setLoading(true);
			this.content.orders = await new OrderModel().getList(this.$route.query);
			this.currentPage = Number(this.$route.query.page);
			this.contentIsReady = true;
			this.setLoading(false);
		}
	}

	/**
	 * Converts Route to Object
	 */
	private getFilterObject(): any {
		let object = {};

		for (const query in this.$route.query) {
			if (query) {
				object = {
					...object,
					[query]: this.$route.query[query]
						.toString()
						.split(",")
						.map(n => parseInt(n, 10))
				};
			}
		}

		return object;
	}

	/**
	 * Ads Params to Route
	 */
	private addFilter(filter: string, id: number, checkbox?: boolean) {
		// Prepares this.filter Object
		let object = {
			page: "1"
		};

		const filterObject = this.getFilterObject();

		// Removes id from Array
		if (filterObject[filter] && filterObject[filter].indexOf(id) >= 0) {
			if (filterObject[filter].length === 1) {
				filterObject[filter] = undefined;
			} else {
				filterObject[filter].splice(filterObject[filter].indexOf(id), 1);
			}
		}

		// Adds id to Array
		else {
			if (!filterObject[filter]) {
				filterObject[filter] = [];
			}
			filterObject[filter].push(id);

			if (!checkbox) {
				filterObject[filter] = [id];
			}
		}

		// Converts Array to Route Object
		for (const key in filterObject) {
			if (filterObject.hasOwnProperty(key) && filterObject[key] !== undefined) {
				const value = filterObject[key];
				object = { ...object, [key]: value.join(",") };
			} else {
				object = { ...object, [key]: undefined };
			}
		}

		// Push Object to Route
		router.push({ query: object });
	}
}
