import { render, staticRenderFns } from "./comissions.page.pug?vue&type=template&id=6b7334bd&scoped=true&lang=pug&"
import script from "./comissions.page.ts?vue&type=script&lang=js&"
export * from "./comissions.page.ts?vue&type=script&lang=js&"
import style0 from "./comissions.page.sass?vue&type=style&index=0&id=6b7334bd&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b7334bd",
  null
  
)

component.options.__file = "comissions.page.vue"
export default component.exports