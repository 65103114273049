import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Variation } from "@/data/variation/variation";
import { Image } from "@/data/image";

import get from "lodash/get";

import store from "@/store";

export class VariationModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wc/v3";
		this.modelName = "products";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(productId: number, variationId: number, queries?: Query): Promise<Variation> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}/${productId}/variations/${variationId}`,
				new Query({ ...queries, lang })
			);

			return this.processEntry(data);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(productId: number, queries?: Query): Promise<Variation[]> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}/${productId}/variations`,
				new Query({ ...queries, lang })
			);

			return data.map((entry: any) => {
				return this.processEntry(entry);
			});
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a Variation entry.
	 */
	private processEntry(data: any): Variation {
		// return data;
		return {
			id: data.id,
			type: data.type,
			prices: {
				price: Number(data.price),
				regularPrice: Number(data.regular_price),
				onSale: Number(data.on_sale),
				salePrice: Number(data.sale_price),
				dateOnSaleFrom: data.date_on_sale_from,
				dateOnSaleTo: data.date_on_sale_to
			},
			stock_status: data.stock_status,
			stock_quantity: data.stock_quantity,
			description: data.description,
			attributes: data.attributes
		};
	}
}
