import { render, staticRenderFns } from "./workshops.page.pug?vue&type=template&id=76d526d0&scoped=true&lang=pug&"
import script from "./workshops.page.ts?vue&type=script&lang=js&"
export * from "./workshops.page.ts?vue&type=script&lang=js&"
import style0 from "./workshops.page.sass?vue&type=style&index=0&id=76d526d0&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76d526d0",
  null
  
)

component.options.__file = "workshops.page.vue"
export default component.exports