import { render, staticRenderFns } from "./page.page.pug?vue&type=template&id=20971381&scoped=true&lang=pug&"
import script from "./page.page.ts?vue&type=script&lang=js&"
export * from "./page.page.ts?vue&type=script&lang=js&"
import style0 from "./page.page.sass?vue&type=style&index=0&id=20971381&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20971381",
  null
  
)

component.options.__file = "page.page.vue"
export default component.exports