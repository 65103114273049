import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { State, Getter, Action, Mutation } from "vuex-class";
import { ProductModel } from "@/data/product/product.model";
import { VariationModel } from "@/data/variation/variation.model";
import { CouponModel } from "@/data/coupon/coupon.model";
import { OrderModel } from "@/data/order/order.model.ts";

@Component({
	name: "Page"
})
export default class PageComponent extends Vue {
	@Action("i18n/changeLanguage") private changeLanguage!: any;
	@Getter("i18n/getAvailableLangs") private availableLangs!: string[];
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = {
		product: null,
		variation: null
	};
	private contentIsReady: boolean = false;
	private step: number = 1;
	private validated: boolean = false;

	private couponCode: string = "";
	private coupon: any = null;
	private couponInvalid: boolean = false;

	private form = {
		payment_method: "",
		payment_method_title: "If Then Pay",
		set_paid: false,
		status: "pending",
		billing: {
			first_name: "",
			last_name: "",
			adress_1: "",
			adress_2: "",
			city: "",
			state: "",
			postcode: "",
			country: "",
			email: "",
			phone: ""
		},
		line_items: [] as any[],
		coupon_lines: [] as any[]
	};

	private maxQuantity: number = 20;

	private created() {
		this.getContent();
	}

	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		this.setLoading(true);
		const productId = Number(this.$route.params.product);
		const variationId = Number(this.$route.params.variation);

		console.log("product Id: ", productId);

		this.content.product = await new ProductModel().getEntry(productId);
		this.content.variation = await new VariationModel().getEntry(productId, variationId);
		this.setLoading(false);

		if (this.content.variation.id === 225) {
			this.form.line_items.push({
				product_id: this.content.product.id,
				variation_id: this.content.variation.id,
				quantity: 10
			});
		} else {
			this.form.line_items.push({
				product_id: this.content.product.id,
				variation_id: this.content.variation.id,
				quantity: 1
			});
		}
		this.contentIsReady = true;
	}

	private async checkCoupon() {
		const response = await new CouponModel().getList({ code: this.couponCode, include: [this.content.product.id] });
		this.setLoading(true);
		console.log("Coupon: ", response[0], "Product IDS: ", this.content.product);

		if (response.length) {
			this.coupon = response[0];
			if (
				this.coupon.product_ids.includes(this.content.product.id) ||
				this.coupon.product_ids.includes(this.content.variation.id)
			) {
				this.form.coupon_lines = [
					{
						code: response[0].code,
						amount: response[0].amount
					}
				];
				this.couponInvalid = false;
			} else {
				this.coupon = [];
				this.form.coupon_lines = [];
				this.couponInvalid = true;
			}
		} else {
			this.coupon = [];
			this.form.coupon_lines = [];
			this.couponInvalid = true;
		}
		this.setLoading(false);
	}

	private checkForm() {
		this.$validator.validateAll().then(async result => {
			if (result) {
				this.step = 3;
			}
		});
	}

	private async submitReserve() {
		this.setLoading(true);
		const response = await new OrderModel().createEntry(this.form);
		this.setLoading(false);

		if (response) {
			// alert(
			// 	`🎉 Thank you, ${this.form.billing.first_name}! We received your Reservation.\nAn e-mail will soon be sent to ${this.form.billing.email}.`
			// );
			this.step = 4;
		}
	}

	private calcCoupon(price: number, discount: number) {
		const subtractValue = price * (discount / 100);
		const total = price - subtractValue;
		return total;
	}
}
