import get from "lodash/get";

export class Image {
	private title?: string;
	private description?: string;
	private caption?: string;
	private alt?: string;
	private sizes: {
		original?: string | null;
		great?: string | null;
		huge?: string | null;
		large?: string | null;
		medium?: string | null;
		small?: string | null;
		thumbnail?: string | null;
	};

	constructor(object: any) {
		this.title = get(object, "title.rendered");
		this.description = get(object, "description.rendered");
		this.caption = get(object, "caption.rendered");
		this.alt = get(object, "alt_text");
		this.sizes = {
			original: this.getUrl({ object, path: ["source_url", "full_url"] }),
			thumbnail: this.getUrl({
				object,
				path: ["media_details.sizes.thumbnail.source_url", "sizes.thumbnail.file"]
			}),
			small: this.getUrl({ object, path: ["media_details.sizes.small.source_url", "sizes.small.file"] }),
			medium: this.getUrl({ object, path: ["media_details.sizes.medium.source_url", "sizes.medium.file"] }),
			large: this.getUrl({ object, path: ["media_details.sizes.large.source_url", "sizes.large.file"] }),
			great: this.getUrl({ object, path: ["media_details.sizes.great.source_url", "sizes.great.file"] }),
			huge: this.getUrl({ object, path: ["media_details.sizes.huge.source_url", "sizes.huge.file"] })
		};
	}

	private getUrl({ object, path }: { object: any; path: string[] }): string | null {
		let url;
		let i = 0;

		while (!url && i < path.length) {
			if (get(object, path[i], null)) {
				url = get(object, path[i]);
			} else {
				i++;
			}
		}

		if (!url) {
			return null;
		} else if (url.indexOf("http") !== -1) {
			return url;
		} else {
			const array = object.full_url.split("/");
			array[array.length - 1] = url;
			return array.join("/");
		}
	}
}
