import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Orator } from "@/data/orator/orator";
import { Image } from "@/data/image";

import get from "lodash/get";

import store from "@/store";

export class OratorModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wp/v2";
		this.modelName = "orators";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(slug: string, queries?: Query): Promise<Orator> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ ...queries, slug, lang })
			);

			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<Orator[]> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ ...queries, lang }));

			return data.map((entry: any) => {
				return this.processEntry(entry);
			});
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	private processEntry(data: any): Orator {
		return {
			id: data.id,
			title: data.title.rendered,
			slug: data.slug,
			portrait: new Image(get(data, "meta_box.portrait[0]")),
			biography: get(data, "meta_box.biography"),
			connections: get(data, "meta_box.connections")
		};
	}
}
