import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Order } from "@/data/order/order";
import { Image } from "@/data/image";

import get from "lodash/get";

import store from "@/store";

export class OrderModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wc/v3";
		this.modelName = "orders";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(orderId: number, queries?: Query): Promise<Order> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}/${orderId}`,
				new Query({ ...queries, lang })
			);

			console.log("🔨Variation Data:", data);
			return this.processEntry(data);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async createEntry(body: any): Promise<Order[]> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.create(`${this.namespace}/${this.modelName}`, body);

			return data;
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<Order[]> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];
		console.log("Order Model :: :: Queries", queries);
		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ ...queries, lang }));

			return data;
			return data.map((entry: any) => {
				return this.processEntry(entry);
			});
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a Variation entry.
	 */
	private processEntry(data: any): Order {
		// return data;
		// console.log("🔨 Variation Data:", data);
		return {
			id: data.id,
			type: data.type,
			prices: {
				price: Number(data.price),
				regularPrice: Number(data.regular_price),
				onSale: Number(data.on_sale),
				salePrice: Number(data.sale_price),
				dateOnSaleFrom: Number(data.date_on_sale_from),
				dateOnSaleTo: Number(data.date_on_sale_to)
			},
			description: data.description,
			attributes: data.attributes
		};
	}
}
