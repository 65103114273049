import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { WorkshopModel } from "@/data/workshop/workshop.model.ts";

import { State, Getter, Action, Mutation } from "vuex-class";

@Component({
	name: "WorkshopPage"
})
export default class PageComponent extends Vue {
	@Action("i18n/changeLanguage") private changeLanguage!: any;
	@Getter("i18n/getAvailableLangs") private availableLangs!: string[];
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = [];
	private contentIsReady: boolean = false;

	private created() {
		console.log("Created!");
		this.getContent();
	}

	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		this.setLoading(true);
		this.content = await new WorkshopModel().getList();
		this.contentIsReady = true;
		this.setLoading(false);
	}
}
