import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default new VueI18n();

// Set language.
// function setLanguage(lang: string) {
//     i18n.locale = lang;
//     document.querySelector("html")!.setAttribute("lang", lang);
//     if (Router.currentRoute.params.lang) {
//         Router.replace({ params: { lang: `${lang}` } });
//     }
//     return lang;
// }

// Load language.
// export function loadLanguage(lang: string) {
// 	if (!availableLangs.includes(lang)) {
// 		lang = defaultLang;
// 	}
// 	if (i18n.locale !== lang) {
// 		if (!loadedLangs.includes(lang)) {
// 			return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/${lang}`).then((msgs) => {
// 				i18n.setLocaleMessage(lang, msgs.default);
// 				loadedLangs.push(lang);
// 				return setLanguage(lang);
// 			});
// 		}
// 		return Promise.resolve(setLanguage(lang));
// 	}
// 	return Promise.resolve(lang);
// }
