import { render, staticRenderFns } from "./home.page.pug?vue&type=template&id=b288819e&scoped=true&lang=pug&"
import script from "./home.page.ts?vue&type=script&lang=js&"
export * from "./home.page.ts?vue&type=script&lang=js&"
import style0 from "./home.page.sass?vue&type=style&index=0&id=b288819e&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b288819e",
  null
  
)

component.options.__file = "home.page.vue"
export default component.exports