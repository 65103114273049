import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Coupon } from "@/data/coupon/coupon";

import get from "lodash/get";

import store from "@/store";

export class CouponModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wc/v3";
		this.modelName = "coupons";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(id: number, queries?: Query): Promise<Coupon> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}/${id}`,
				new Query({ ...queries, lang })
			);

			console.log("🔨 Coupon Data:", data);
			return this.processEntry(data);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<Coupon[]> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ ...queries, lang }));

			return data.map((entry: any) => {
				return this.processEntry(entry);
			});
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a Coupon entry.
	 */
	private processEntry(data: any): Coupon {
		return data;
		return {
			id: data.id,
			code: data.code,
			amount: data.amount,
			description: data.description,
			product_ids: data.product_ids,
			discount_type: data.discount_type,
			usage_limit: data.usage_limit
		};
	}
}
