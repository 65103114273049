import { render, staticRenderFns } from "./orator.block.pug?vue&type=template&id=d75fa012&scoped=true&lang=pug&"
import script from "./orator.block.ts?vue&type=script&lang=ts&"
export * from "./orator.block.ts?vue&type=script&lang=ts&"
import style0 from "./orator.block.sass?vue&type=style&index=0&id=d75fa012&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d75fa012",
  null
  
)

component.options.__file = "orator.block.vue"
export default component.exports