import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import i18n from "@/i18n";

import { State, Getter, Action, Mutation } from "vuex-class";

@Component({
	name: "HeaderBlock"
})
export default class HeaderPage extends Vue {
	@Action("i18n/changeLanguage") private changeLanguage!: any;
	@Getter("i18n/getAvailableLangs") private availableLangs!: string[];

	private hasScrolled: boolean = false;
	private menuIsOpen: boolean = false;

	private created() {
		this.evalScroll();
		window.addEventListener("scroll", this.evalScroll);
	}

	private cleanMenuState() {
		this.toggleMenu(false);
		(document.activeElement as HTMLElement).blur();
	}

	private toggleMenu(force?: boolean): void {
		this.menuIsOpen = force !== undefined ? force : !this.menuIsOpen;
		document.body.classList.toggle("no--scroll", force);
	}

	private evalScroll() {
		this.hasScrolled = window.scrollY > 150 ? true : false;
	}
}
