import { Vue, Component, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { State, Getter, Action, Mutation } from "vuex-class";

import { PageModel } from "@/data/page/page.model.ts";

@Component({
	name: "Page"
})
export default class PageComponent extends Vue {
	@Action("i18n/changeLanguage") private changeLanguage!: any;
	@Getter("i18n/getAvailableLangs") private availableLangs!: string[];
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = {};
	private contentIsReady: boolean = false;

	private isHover: boolean = false;

	private created() {
		console.log("Created!");
		this.getContent();
	}

	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		if (this.i18nHasInitialized) {
			this.setLoading(true);
			this.content = await new PageModel().getTemplatePage({ slug: "pages/call-page.php" });
			this.contentIsReady = true;
			this.setLoading(false);
			console.log("Content", this.content);
		}
	}
}
