import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Product } from "@/data/product/product";
import { VariationModel } from "@/data/variation/variation.model";
import { Image } from "@/data/image";

import get from "lodash/get";

import store from "@/store";

export class ProductModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wc/v3";
		this.modelName = "products";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(id: number, queries?: Query): Promise<Product> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}/${id}`,
				new Query({ ...queries, lang })
			);

			return this.processEntry(data);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(withVariations?: boolean, queries?: Query): Promise<Product[]> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ ...queries, lang }));

			if (withVariations) {
				const products = data.map((entry: any) => {
					return this.processEntry(entry);
				});

				const variationRequestList = [];

				for (const product of products) {
					if (product && product.variations.length) {
						variationRequestList.push(new VariationModel().getList(product.id));
					}
				}

				// Get content data
				const variationData = await Promise.all(variationRequestList);

				// Assign variations data to product
				for (const product of products) {
					// console.log("product", product);

					product.variations = product.variations.map((variationId: number) => {
						// console.log("variationId", variationId);

						return (variationData as any).flat().find((variation: any) => variationId === variation.id);
					});
				}

				return products;
			} else {
				return data.map((entry: any) => {
					return this.processEntry(entry);
				});
			}
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a Product.
	 */
	private processEntry(data: any): Product {
		return {
			id: data.id,
			name: data.name,
			slug: data.slug,
			type: data.type,
			status: data.status,
			description: data.description,
			prices: {
				price: Number(data.price),
				regularPrice: Number(data.regular_price),
				onSale: Number(data.on_sale),
				salePrice: Number(data.sale_price),
				dateOnSaleFrom: data.date_on_sale_from,
				dateOnSaleTo: data.date_on_sale_to
			},
			stock_status: data.stock_status,
			stock_quantity: data.stock_quantity,
			attributes: data.attributes,
			variations: data.variations
		};
	}
}
