import { render, staticRenderFns } from "./contacts.page.pug?vue&type=template&id=3225eb3d&scoped=true&lang=pug&"
import script from "./contacts.page.ts?vue&type=script&lang=js&"
export * from "./contacts.page.ts?vue&type=script&lang=js&"
import style0 from "./contacts.page.sass?vue&type=style&index=0&id=3225eb3d&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3225eb3d",
  null
  
)

component.options.__file = "contacts.page.vue"
export default component.exports