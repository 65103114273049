import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import store from "./store";
import "./registerServiceWorker";

// Library
import * as VeeValidate from "vee-validate";
// import * as VueGoogleMaps from "vue2-google-maps";
// import VueMoment from "vue-moment";
import Moment from "moment";

// Blocks
import Vector from "@/components/blocks/vector/vector.vue";
import ImageBlock from "@/components/blocks/image/image.block.vue";
import HeaderBlock from "@/components/blocks/header-block/header.block.vue";
import FooterBlock from "@/components/blocks/footer-block/footer.block.vue";
import OratorBlock from "@/components/blocks/orator/orator.block.vue";
import LoadingScreen from "@/components/blocks/loading-screen/loading.screen.vue";
// import FileBlock from "@/components/blocks/file-block/file.block.vue";

// import CapitalizeFilter from "@/filters/capitalize.filter";
// import DateFilter from "@/filters/date.filter";
// import CurrencyFilter from "@/filters/currency.filter";
// import StripHtmlFilter from "@/filters/strip-html.filter";

Vue.config.productionTip = false;

// Blocks.
Vue.component("vector", Vector);
Vue.component("image-block", ImageBlock);
Vue.component("header-block", HeaderBlock);
Vue.component("footer-block", FooterBlock);
Vue.component("orator-block", OratorBlock);
Vue.component("loading-screen", LoadingScreen);
// Vue.component("file-block", FileBlock);

// console.log("language is", Moment.locale());

// Library
Vue.use(VeeValidate);
// tslint:disable-next-line: no-var-requires
Vue.use(require("vue-moment"), { moment: Moment });

// tslint:disable-next-line: no-var-requires
Vue.use(require("vue2-google-maps"), {
	load: {
		key: "AIzaSyCVOPuZgLD4SPekFvVXSUhMkMt5XTMnXrU",
		libraries: "places" // This is required if you use the Autocomplete plugin
	}
});

// Filters.
// Vue.filter("capitalize", CapitalizeFilter);
// Vue.filter("date", DateFilter);
// Vue.filter("currency", CurrencyFilter);
// Vue.filter("stripHtml", StripHtmlFilter);

// The Vue instance.
export default new Vue({
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount("#app");

// Initialization of stores.
store.dispatch("i18n/initModule");

declare module "vue/types/vue" {
	interface Vue {
		$moment: any;
		moment: any;
	}
}
