import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { PageModel } from "@/data/page/page.model.ts";
import { ProductModel } from "@/data/product/product.model.ts";

import { State, Getter, Action, Mutation } from "vuex-class";

@Component({
	name: "Page"
})
export default class PageComponent extends Vue {
	@Action("i18n/changeLanguage") private changeLanguage!: any;
	@Getter("i18n/getAvailableLangs") private availableLangs!: string[];
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = [];
	private contentIsReady: boolean = false;
	private waitingForContent: boolean = false;

	private activeProduct: string = "";
	private activeVariation: string = "";
	private activeDate: string = "";
	private activePrice: number = 0;

	private created() {
		this.getContent();

		// console.log("Moment Root: ", this.$root.$moment.locale);
	}

	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		if (this.i18nHasInitialized && !this.waitingForContent) {
			this.waitingForContent = true;
			this.setLoading(true);
			this.content = await new ProductModel().getList(true);

			this.waitingForContent = false;
			this.contentIsReady = true;
			this.setLoading(false);
		}
	}
}
