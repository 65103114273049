import { Vue, Component, Prop, Provide } from "vue-property-decorator";

@Component({
	name: "OratorBlock"
})
export default class OratorBlockComponent extends Vue {
	@Prop() private title!: string;
	@Prop() private biography!: string;
	@Prop() private portrait!: any;
	@Prop() private connections!: any[];
	@Prop() private index!: number;

	@Provide() private isOpen: boolean = false;
}
