import Vue from "vue";
import Router from "vue-router";

import HomePage from "@/components/pages/home/home.page.vue";
import PricesPage from "@/components/pages/prices/prices.page.vue";
import ReservesPage from "@/components/pages/reserves/reserves.page.vue";
import SamplePage from "@/components/pages/page/page.page.vue";
import EditionsPage from "@/components/pages/editions/editions.page.vue";
import ComissionsPage from "@/components/pages/comissions/comissions.page.vue";
import OratorsPage from "@/components/pages/orators/orators.page.vue";
import WorkshopsPage from "@/components/pages/workshops/workshops.page.vue";
import ContactsPage from "@/components/pages/contacts/contacts.page.vue";
import ProgramPage from "@/components/pages/program/program.page.vue";
import OutputPage from "@/components/pages/output/output.page.vue";

Vue.use(Router);

const router = new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: "/pt"
		},
		{
			path: "/:lang",
			component: HomePage,
			name: "HomePage"
		},
		{
			path: "/:lang/editions",
			component: EditionsPage,
			name: "EditionsPage"
		},
		{
			path: "/:lang/registration",
			component: PricesPage,
			name: "PricesPage",
			children: [
				{
					path: "reserves/product/:product/variation/:variation",
					name: "ReservesPage",
					components: {
						modal: ReservesPage
					}
				}
			]
		},
		{
			path: "/:lang/orators",
			component: OratorsPage,
			name: "OratorsPage"
		},
		{
			path: "/:lang/comissions",
			component: ComissionsPage,
			name: "ComissionsPage"
		},
		{
			path: "/:lang/contacts",
			component: ContactsPage,
			name: "ContactsPage"
		},
		{
			path: "/:lang/workshops",
			component: WorkshopsPage,
			name: "WorkshopsPage"
		},
		{
			path: "/:lang/program",
			component: ProgramPage,
			name: "ProgramPage"
		},
		{
			path: "/:lang/output",
			component: OutputPage,
			name: "OutputPage"
		},
		{
			path: "/:lang/:page",
			component: SamplePage,
			name: "SamplePage"
		},
		{
			path: "**",
			redirect: "/404-error"
		}
	]
});

router.beforeEach(async (to, from, next) => {
	// Merge query parameters
	if (!hasQueryParams(to) && hasQueryParams(from)) {
		next({ name: to.name, params: to.params, query: { ...from.query, ...to.query }, hash: to.hash });
	} else {
		next();
	}
});

function hasQueryParams(route: any) {
	return !!Object.keys(route.query).length;
}

export default router;
