import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import i18n from "@/i18n";

import { PageModel } from "@/data/page/page.model.ts";

import { State, Getter, Action, Mutation } from "vuex-class";

@Component({
	name: "ContactsPage"
})
export default class ContactsPage extends Vue {
	@Action("i18n/changeLanguage") private changeLanguage!: any;
	@Getter("i18n/getAvailableLangs") private availableLangs!: string[];
	@Getter("i18n/getCurrentLang") private currentLang!: string;
	@Getter("i18n/hasInitialized") private i18nHasInitialized!: boolean;

	@Mutation("state/setLoading") private setLoading!: any;

	private content: any = {};
	private openWindow: number = -1;
	private contentIsReady: boolean = false;
	private mapStyle: object = {
		styles: [
			{
				elementType: "geometry",
				stylers: [
					{
						color: "#212121"
					}
				]
			},
			{
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#000000"
					}
				]
			},
			{
				elementType: "labels.icon",
				stylers: [
					{
						visibility: "off"
					}
				]
			},
			{
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#757575"
					}
				]
			},
			{
				elementType: "labels.text.stroke",
				stylers: [
					{
						color: "#212121"
					}
				]
			},
			{
				featureType: "administrative",
				elementType: "geometry",
				stylers: [
					{
						color: "#757575"
					}
				]
			},
			{
				featureType: "administrative.country",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#9e9e9e"
					}
				]
			},
			{
				featureType: "administrative.land_parcel",
				stylers: [
					{
						visibility: "off"
					}
				]
			},
			{
				featureType: "administrative.locality",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#bdbdbd"
					}
				]
			},
			{
				featureType: "poi",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#757575"
					}
				]
			},
			{
				featureType: "poi.park",
				elementType: "geometry",
				stylers: [
					{
						color: "#181818"
					}
				]
			},
			{
				featureType: "poi.park",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#616161"
					}
				]
			},
			{
				featureType: "poi.park",
				elementType: "labels.text.stroke",
				stylers: [
					{
						color: "#1b1b1b"
					}
				]
			},
			{
				featureType: "road",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#2c2c2c"
					}
				]
			},
			{
				featureType: "road",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#8a8a8a"
					}
				]
			},
			{
				featureType: "road.arterial",
				elementType: "geometry",
				stylers: [
					{
						color: "#373737"
					}
				]
			},
			{
				featureType: "road.arterial",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#008c00"
					}
				]
			},
			{
				featureType: "road.highway",
				elementType: "geometry",
				stylers: [
					{
						color: "#3c3c3c"
					}
				]
			},
			{
				featureType: "road.highway",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#00ff00"
					}
				]
			},
			{
				featureType: "road.highway.controlled_access",
				elementType: "geometry",
				stylers: [
					{
						color: "#4e4e4e"
					}
				]
			},
			{
				featureType: "road.highway.controlled_access",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#00ff00"
					}
				]
			},
			{
				featureType: "road.highway.controlled_access",
				elementType: "geometry.stroke",
				stylers: [
					{
						weight: 1
					}
				]
			},
			{
				featureType: "road.local",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#005000"
					}
				]
			},
			{
				featureType: "road.local",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#616161"
					}
				]
			},
			{
				featureType: "transit",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#757575"
					}
				]
			},
			{
				featureType: "water",
				elementType: "geometry",
				stylers: [
					{
						color: "#000000"
					}
				]
			},
			{
				featureType: "water",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#232323"
					}
				]
			},
			{
				featureType: "water",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#3d3d3d"
					}
				]
			}
		],
		mapTypeId: "roadmap"
	};

	private markers: any = [
		{
			id: 1,
			title: "Cine-Teatro Constantino Nery",
			address: "Av. Serpa Pinto 242, 4450-275 Matosinhos",
			position: {
				lat: 41.184376,
				lng: -8.694914
			},
			markerIcon: {
				url: "/icons/marker.svg",
				scaledSize: { width: 48, height: 48 }
			}
		},
		{
			id: 2,
			title: "Escola Superior de Artes e Design",
			address: "Av. Calouste Gulbenkian, 4460-268 Sra. da Hora",
			position: {
				lat: 41.185256,
				lng: -8.662429
			},
			markerIcon: {
				url: "/icons/marker.svg",
				scaledSize: { width: 48, height: 48 }
			}
		},
		{
			id: 3,
			title: "esad—idea",
			address: "Rua de Brito Capelo 243, 4450-073 Matosinhos",
			position: {
				lat: 41.185428,
				lng: -8.692629
			},
			markerIcon: {
				url: "/icons/marker.svg",
				scaledSize: { width: 48, height: 48 }
			}
		}
	];

	private created() {
		this.getContent();
	}

	@Watch("currentLang")
	@Watch("i18nHasInitialized")
	private async getContent() {
		if (this.i18nHasInitialized) {
			this.setLoading(true);
			this.content = await new PageModel().getTemplatePage({ slug: "pages/home-page.php" });
			this.contentIsReady = true;
			this.setLoading(false);
		}
	}
}
