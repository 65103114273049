import { render, staticRenderFns } from "./output.page.pug?vue&type=template&id=36c91f99&lang=pug&"
import script from "./output.page.ts?vue&type=script&lang=js&"
export * from "./output.page.ts?vue&type=script&lang=js&"
import style0 from "./output.page.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "output.page.vue"
export default component.exports