






import { Vue, Component, Prop, Provide } from "vue-property-decorator";

@Component({})

export default class ButtonBlockComponent extends Vue {

	@Prop() private src!: string;
	@Prop() private url!: string;
	
	private created() {
		console.log("URL:", require(this.src))
		this.url = require(this.src);
	}
}
