import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import get from "lodash/get";

@Component({
	name: "ImageBlock"
})
export default class ImageBlock extends Vue {
	@Prop() private wordpress!: boolean; // Object containing images of multiple sizes.
	@Prop() private source!: object; // Object containing images of multiple sizes.
	@Prop() private caption!: string; // Caption to display
	@Prop() private alt!: string; // Alternative text
	@Prop() private color!: string; // Background color. Used before the image is loaded.
	@Prop() private lazy!: boolean; // Lazy load the image when viewport contains the image.
	// Lazy load the image when viewport contains the image.
	// @Prop() private size!: string; // TODO: repensar nisto, o melhor será o componente perceber.
	// @Prop() private isInteractive: boolean; // Conditional for directive 'imageInteractive'.

	private size: string = "";
	private url: string = "";
	private load: boolean = true;

	// @Provide() private load!: boolean;

	private created() {
		window.addEventListener("resize", () => {
			if (this.wordpress) {
				this.evalSize();
			}
		});
	}

	private mounted() {
		if (this.wordpress) {
			this.lazyLoad();
			this.evalSize();
		}
	}

	/**
	 * Evaluate the size of an image, based on it's Width and Height.
	 */
	private evalSize() {
		const elementWidth = this.$el.parentElement!.offsetWidth;
		const elementHeight = this.$el.parentElement!.offsetHeight;

		const sizes = {
			huge: 1440,
			great: 1024,
			large: 768,
			medium: 320
		};

		const widthHeightRatio = 3;

		let size = this.size;

		if (elementWidth > sizes.huge || elementHeight > sizes.huge / widthHeightRatio) {
			size = "huge";
		} else if (
			this.size !== "huge" &&
			(elementWidth > sizes.great || elementHeight > sizes.great / widthHeightRatio)
		) {
			size = "great";
		} else if (
			this.size !== "great" &&
			this.size !== "huge" &&
			(elementWidth > sizes.large || elementHeight > sizes.large / widthHeightRatio)
		) {
			size = "large";
		} else if (
			this.size !== "large" &&
			this.size !== "great" &&
			this.size !== "huge" &&
			(elementWidth > sizes.medium || elementHeight > sizes.medium / widthHeightRatio)
		) {
			size = "medium";
		} else if (
			this.size !== "medium" &&
			this.size !== "large" &&
			this.size !== "great" &&
			this.size !== "huge" &&
			elementWidth > 0
		) {
			size = "small";
		}
		if (get(this.source, `sizes[${size}]`) == null) {
			size = "original";
		}

		this.size = size;

		this.url = get(this.source, `sizes[${size}]`);
	}

	/**
	 * Evaluate whenever to load image, based on it's visibility.
	 */
	private lazyLoad() {
		if ("IntersectionObserver" in window) {
			const imageEl = this.$el;

			// IntersectionObserver options.
			const options = {
				root: null,
				rootMargin: "0px",
				threshold: 0
			};

			// If visibile, load image.
			const handleIntersection = (entries: any) => {
				entries.forEach((entry: any) => {
					// console.log("IntersectionRatio", entry.intersectionRatio);
					if (entry.intersectionRatio > 0) {
						// console.log("image", image.querySelector("img"))
						// loadImage(image.querySelector("img"))
						this.load = true;
					}
				});
			};

			// Observe the image based on previous configurations
			const observer = new IntersectionObserver(handleIntersection, options);
			observer.observe(imageEl);
		} else {
			this.load = true;
		}
	}
}
