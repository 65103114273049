import { WpApi } from "@/data/wp-api";
import { Query } from "@/data/query";
import { Workshop } from "@/data/workshop/workshop";
import { Image } from "@/data/image";

import get from "lodash/get";

import store from "@/store";

export class WorkshopModel {
	private namespace: string;
	private modelName: string;
	private api: WpApi;

	constructor() {
		this.namespace = "wp/v2";
		this.modelName = "workshops";
		this.api = new WpApi();
	}

	/**
	 * Returns a single page.
	 */
	public async getEntry(slug: string, queries?: Query): Promise<Workshop> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(
				`${this.namespace}/${this.modelName}`,
				new Query({ ...queries, slug, lang })
			);

			return this.processEntry(data[0]);
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Returns a list of pages.
	 */
	public async getList(queries?: Query): Promise<Workshop[]> {
		const lang = store.getters["i18n/getCurrentWpmlLang"];

		try {
			const data = await this.api.read(`${this.namespace}/${this.modelName}`, new Query({ ...queries, lang }));

			return data.map((entry: any) => {
				return this.processEntry(entry);
			});
		} catch (error) {
			throw Error(error);
		}
	}

	/**
	 * Processes a page entry.
	 */
	private processEntry(data: any): Workshop {
		return {
			id: data.id,
			title: data.title.rendered,
			slug: data.slug,
			date: data.meta_box.date,
			info: data.meta_box.info,
			teacher: data.meta_box.teacher,
			teacher_biography: data.meta_box.teacher_biography,
			link: data.meta_box.products
		};
	}
}
