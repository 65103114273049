import { render, staticRenderFns } from "./reserves.page.pug?vue&type=template&id=20684ccf&lang=pug&"
import script from "./reserves.page.ts?vue&type=script&lang=js&"
export * from "./reserves.page.ts?vue&type=script&lang=js&"
import style0 from "./reserves.page.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "reserves.page.vue"
export default component.exports